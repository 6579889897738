






export default {
  name: 'sectionTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false
    }
  }
}
