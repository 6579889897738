







export default {
  name: 'Functionalities',
  props: {
    cards: {
      type: Array,
      required: true
    }
  }
}
