















































export default {
  name: 'Plan',
  props: {
    plans: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    step: 1,
  })
}
