






























































































































import Vue             from 'vue';
import Navbar          from '@/components/Navbar.vue'
import Testeminie      from "@/components/Testeminie.vue";
import Plan            from "@/components/Plan.vue";
import Functionalities from "@/components/Functionalities.vue";
import SectionTitle    from "@/components/SectionTitle.vue";
import Modal           from "@/components/Modal.vue";

export default Vue.extend({
  name: 'Home',
  components: { Modal, SectionTitle, Functionalities, Plan, Testeminie, Navbar },
  filters: {
    currency(value: number) {
      return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value).slice(0, -3)
    }
  },
  data: () => ({
    meetingModal: false,
    benefits: [
      {
        id: 1,
        title: 'Muito mais <span class="text-blue-500">RESULTADO</span> em <span class="text-blue-500">QUALQUER</span> plataforma!',
        description: 'Seus seguidores irão diretamente para suas páginas sociais dentro do aplicativo Youtube, Instagram ou qualquer outro aplicativo, tornando muito fácil para eles segui-lo e engajar em todas as suas redes sociais.',
        image: () => require('@/assets/images/social_media.svg')
      },
      {
        id: 2,
        title: '<span class="text-blue-500">Rastreie</span> da melhor maneira suas <span class="text-blue-500">visualizações</span>, <span class="text-blue-500">cliques</span> e <span class="text-blue-500">conversões</span>',
        description: 'Com a AppClick, você pode integrar com as principais ferramentas de rastreamento do mercado como o pixel do Facebook, Google Analytics, Google Tag Manager, Google AdWords, em qualquer link seja no Youtube, IFOOD, ou qualquer outra rede social.',
        image: () => require('@/assets/images/data_analytics.svg')
      },
      {
        id: 3,
        title: '8x mais <span class="text-blue-500">receita de anúncios</span> do <span class="text-blue-500">YouTube</span> com <span class="text-blue-500">tráfego externo</span>',
        description: 'Hoje, quando seus seguidores passam do Instagram e Snapchat para o seu último vídeo do Youtube, eles não veem nenhum anúncio. Ao usar AppClick para promover seus vídeos mais recentes, seus seguidores acabarão diretamente no aplicativo do Youtube, permitindo que você maximize a receita de anúncios.',
        image: () => require('@/assets/images/marketing.svg')
      },
      {
        id: 4,
        title: 'Explore <span class="text-blue-500">todas as dimensões</span> e <span class="text-blue-500">métricas</span> do <span class="text-blue-500">seu negócio</span>',
        description: 'Seus seguidores irão diretamente para suas páginas sociais dentro do aplicativo Youtube, Instagram ou qualquer outro aplicativo, tornando muito fácil para eles segui-lo e engajar em todas as suas redes sociais.',
        image: () => require('@/assets/images/analyzing_process.svg')
      },
    ],
    cards: [
      {
        id: 1,
        title: 'Nenhum SDK necessário',
        description: 'Economize tempo e recursos com nossos recursos de deep linking. Nenhuma implementação técnica necessária para a criação, rastreamento ou atribuição do link.',
        image: ''
      },
      {
        id: 1,
        title: 'Altere seus links instantâneamente',
        description: 'Atualize o destino do aplicativo ou destinos de fallback da web a qualquer momento, mesmo depois que seus links forem implantados nas campanhas.',
        image: ''
      },
      {
        id: 1,
        title: 'Remarketing de link',
        description: 'Crie poderosas campanhas de remarketing de seus clientes / visitantes assim que eles abrirem qualquer um de seus links.',
        image: ''
      },
      {
        id: 1,
        title: 'Rastreamento de Pixel',
        description: 'Integrado com as principais tecnologias de rastreamento de pixels para rastrear a conversão e redirecionar todos os usuários que clicarem.',
        image: ''
      },
      {
        id: 1,
        title: 'Relatório Completo',
        description: 'Rastreie e obtenha dados em tempo real sobre seus links como localização do usuário, dispositivo e sistema operacional.',
        image: ''
      },
      {
        id: 1,
        title: 'Nome de domínio personalizado',
        description: 'Use seu próprio nome de domínio antes de compartilhar um link como <span class="text-blue-500">link.minhaempresa.com</span>',
        image: ''
      },
    ],
    clients: [
      {
        id: 1,
        name: 'Perter Jordan',
        jobtitle: 'Canal Youtube Nerds de Negócios',
        description: 'A plataforma ajudou a todos os meus colaborares a crescerem entorno de 12% ao ano.',
        image: () => require("@/assets/images/peter.png")
      },
      {
        id: 2,
        name: 'Juliano Torriani',
        jobtitle: 'Canal Youtube Juliano Torriani',
        description: 'A plataforma ajudou a todos os meus colaborares a crescerem entorno de 12% ao ano.',
        image: () => require("@/assets/images/juliano.png")
      },
      {
        id: 3,
        name: 'Andre Cia',
        jobtitle: 'Canal Youtube Andre Cia - Copy Hacks',
        description: 'A plataforma ajudou a todos os meus colaborares a crescerem entorno de 12% ao ano.',
        image: () => require("@/assets/images/andre.png")
      },
    ],
    plans: [
      { id: 3, feature: 'Experiências de link de aplicativo para iOS e Android', freeTrial: true, starter: true, pro: true, contact: true },
      { id: 4, feature: 'Roteamento condicional de iPhone, iPad, telefone / tablet Android', freeTrial: true, starter: true, pro: true, contact: true },
      { id: 5, feature: 'Criptografia HTTPS automática em todos os URLs', freeTrial: true, starter: true, pro: true, contact: true },
      { id: 6, feature: 'Relatório em tempo REAL de LOCALIZAÇÃO, DISPOSITIVOS, SISTEMA OPERACIONAL', freeTrial: true, starter: true, pro: true, contact: true },
      { id: 7, feature: 'QR CODE DINAMICO', freeTrial: true, starter: true, pro: true, contact: true },
      { id: 8, feature: 'Google Analytics em todos os Links', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 9, feature: 'Altere os destinos do link a qualquer momento', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 10, feature: 'Google TAG MANAGER', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 11, feature: 'Pixel do Facebook em todos os links', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 12, feature: 'Pixel do TABOOLA', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 13, feature: 'Pixel do TWITTER', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 14, feature: 'Pixel do LINKDLIN', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 15, feature: 'Links Personalizado com o próprio dominio', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 16, feature: 'Dominio Personalizado', freeTrial: false, starter: true, pro: true, contact: true },
      { id: 17, feature: 'UTM SOURCE, REF, MEDIUM', freeTrial: false, starter: false, pro: true, contact: true },
      { id: 18, feature: 'Encurtador de Link', freeTrial: false, starter: false, pro: true, contact: true },
      { id: 19, feature: 'Suporte Via EMAIL', freeTrial: false, starter: false, pro: true, contact: true },
      { id: 20, feature: 'Suporte Via WhatsApp', freeTrial: false, starter: false, pro: false, contact: true },
    ]
  })
});
