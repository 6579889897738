<template lang="pug">
  div(class="max-w-7xl mx-auto")
    div(id="navbar")
      nav(class="grid grid-cols-1 lg:grid-cols-2 items-center justify-between py-5 px-10 md:px-4")
        div(class="flex justify-between w-full items-center")
          img(class="w-32" src="@/assets/logo.svg")
          button(class="lg:hidden" @click.prevent="opened = !opened")
            img(v-if="opened" src="@/assets/icons/x.svg")
            img(v-else src="@/assets/icons/menu.svg")

        div(:class="{ hidden: !opened }" class="lg:flex items-center bg-white p-3 my-5 rounded-md")
          ul(class="flex flex-col lg:flex-row items-center text-center w-full items-center")
            li(class="px-3 py-4 whitespace-nowrap font-bold antialised text-black border-b md:border-none border-gray-100 w-full")
              a(href="#functionalities") Funcionalidades
            li(class="px-3 py-4 whitespace-nowrap font-bold antialised text-black border-b md:border-none border-gray-100 w-full")
              a(href="#about") Sobre nós
            li(class="px-3 py-4 whitespace-nowrap font-bold antialised text-black border-b md:border-none border-gray-100 w-full")
              a(href="#prices") Planos
            li(class="px-3 py-4 whitespace-nowrap font-bold antialised text-black border-b md:border-none border-gray-100 w-full")
              button(@click.prevent="$emit('meeting')" class="bg-white-500 px-6 rounded-md font-bold py-2 text-black border border-gray-100 hover:bg-blue-500 hover:text-white") Agendar
            li(class="px-3 py-4 font-bold antialised text-blackw-full")
              button(class="bg-green-500 px-6 rounded-md font-bold py-2 text-white") Login
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    opened: false
  })
}
</script>

<style scoped>

</style>
