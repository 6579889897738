<template lang="pug">
  div(class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true")
    div(class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0")
      span(class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true")
      slot

</template>

<script>
export default {
  name: "Modal",
  methods: {
    closeModal () {
      console.log('cheguei até aqui');
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped>

</style>
